.appSearchField {
  margin-bottom: 24px;
  display: block;
}

.appSearchField input {
  width: 100%;
  border: thin solid var(--separator-color);
  font: inherit;
  padding: 8px 16px;
}
