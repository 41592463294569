.relatedArticlesList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.relatedArticlesList li {

}

.relatedArticlesList li + li {
    border-top: thin solid var(--separator-color);
}
