.appSidebar { 
  flex: 0 0 300px;
  margin-right: 32px;
}

.appSidebar fieldset {
  margin: 1em 0;
}

.appSidebar legend {
  font-weight: lighter;
  font-size: 1.5em;
}

@media screen and (max-width: 1000px) {
  .appSidebar {
    box-sizing: border-box;
    max-width: 100vw;
    width: 460px;
    margin-right: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: var(--background-color);
    padding: 24px;
    transform: translate3d(-100%, 0, 0);
    transition: transform 200ms ease-in-out;
    border-right: thin solid var(--separator-color);
    z-index: 1;
  }

  .appSidebarOpen {
    transform: translate3d(0, 0, 0);
  }

  .appSidebarOpen ~ * {
    height: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
  }
}
