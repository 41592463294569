.filterList {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px 18px;
  margin: 0;
  padding: 0;
}

.filterList li {

}
