.appNav {
}

.appNav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.appNav li {
  margin-right: 16px;
}

@media screen and (max-width: 1000px) {
  .appNav {
    width: 100%;
  }

  .appNav ol {
    justify-content: stretch;
  }

  .appNav li {
    flex: 1;
    text-align: center;
    margin: 0;
  }

  .appNav a {
    display: block;
    padding: 16px;
  }
}
