.mobileNav {
  display: none;
}

@media screen and (max-width: 1000px) {
  .mobileNav {
    display: block;
    position: absolute;
    left: 16px;
    top: 16px;
  }
}
