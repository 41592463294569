.shareList {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.shareList li {
  margin: 0 16px;
  cursor: pointer;
}

.shareList svg {
  height: 48px;
}
