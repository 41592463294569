.articleListLink {
  text-decoration: none;
  color: inherit;
  padding: 16px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .articleListLink {
    display: block;
  }
}
