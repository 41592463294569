.appHeader {
  border-bottom: 1px solid var(--separator-color);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.appHeaderInverted a {
  color: currentColor;
}

.appHeader path, .appHeader line {
  fill: currentColor;
  stroke: currentColor;
}

@media screen and (max-width: 1000px) {
  .appHeader {
    flex-direction: column-reverse;
    padding: 0;
    flex: 0 0 auto;
  }

  .appHeaderInverted {
    background: var(--color-main);
    color: var(--background-color);
  }
}
