.gameBlock {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  padding: 16px;
}

.gameBlock img {
  width: 100%;
  display: block;
}
