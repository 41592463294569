.appPage {
  display: flex;
  justify-content: center;
  max-width: var(--breakpoint-max);
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
  .appPage {
    width: 100%;
    display: block;
    position: relative;
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
  }
}
