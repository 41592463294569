.articleBlock {

}

.articleBlock img {
  max-width: 100%;
  display: block;
}

.articleBlock .articleContent {
  padding: 48px 96px;
}

.articleBlock .articleMedia + .articleContent {
  padding-top: 32px;
}

.articleBlockList {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  margin-bottom: 16px;
  border-radius: 8px;
}

.articleBlockList .articleMedia {
  flex: 0 0 200px;
  margin-right: 16px;
}

.articleBlockList .articleContent,
.articleBlockList .articleMedia + .articleContent {
  padding: 0;
}

.articleExcerpt {
  margin-bottom: 16px;
  font-size: 1.1em;
}

.articleBlock h1 {
  margin: 0 0 8px;
  font-size: 1.4em;
  font-weight: normal;
}

.articleBlock h2 {
  font-size: 1.2em;
  font-weight: normal;
  margin: 1.2em 0 0.2em;
}

.articleBlock h3 {
  font-size: 1em;
  font-weight: normal;
}

.articleBlock .articleMeta {
  font-size: 12px;
  opacity: 0.6;
  font-weight: normal;
  display: block;
}

@media screen and (max-width: 1000px) {
  .articleBlock .articleMedia + .articleContent {
    padding-top: 16px;
  }

  .articleBlockList .articleMedia {
    margin: 0;
  }

  .articleBlockList .acticleContent {
    padding: 8px;
  }

  .articleBlock .articleContent {
    padding: 0;
  }
}
