@keyframes spin {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.spinner {
  animation: spin infinite 1s linear;
}