:root {
  /* Colors */
  --separator-color: black;
  --background-color: white;
  --color-link-main: blue;
  --color-main: black;

  /* Fonts */
  --font-size-main: 1rem;
  --font-family-main: Helvetica, Arial, sans-serif;

  /* Breakpoints */
  --breakpoint-min: 1000px;
  --breakpoint-max: 1200px;
}

body {
  margin: 0;
  font-size: var(--font-size-main);
  font-family: var(--font-family-main);
  color: var(--color-main);
  background: var(--background-color-main)
}

@media screen and (max-width: 1000px) {
  #root {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100vh;
  }
}

.mobileNavButton_mobileNavButton__1kyhc {
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  margin: 0;
  color: currentColor;
}

.header_appHeader__2DNOV {
  border-bottom: 1px solid var(--separator-color);
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: stretch;
          justify-content: stretch;
}

.header_appHeaderInverted__tPkTR a {
  color: currentColor;
}

.header_appHeader__2DNOV path, .header_appHeader__2DNOV line {
  fill: currentColor;
  stroke: currentColor;
}

@media screen and (max-width: 1000px) {
  .header_appHeader__2DNOV {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 0;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
  }

  .header_appHeaderInverted__tPkTR {
    background: var(--color-main);
    color: var(--background-color);
  }
}

.appLogo_appLogo__2S4DW {
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: lighter;
}

@media screen and (max-width: 1000px) {
  .appLogo_appLogo__2S4DW {
    padding: 16px 0 0;
  }
}

.appNav_appNav__22tkn {
}

.appNav_appNav__22tkn ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}

.appNav_appNav__22tkn li {
  margin-right: 16px;
}

@media screen and (max-width: 1000px) {
  .appNav_appNav__22tkn {
    width: 100%;
  }

  .appNav_appNav__22tkn ol {
    -webkit-justify-content: stretch;
            justify-content: stretch;
  }

  .appNav_appNav__22tkn li {
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
    margin: 0;
  }

  .appNav_appNav__22tkn a {
    display: block;
    padding: 16px;
  }
}

.appNavItem_appNavItem__1-EEV {
  text-decoration: none;
  color: var(--color-link-main);
}

.appNavItem_appNavItemActive__LNRgE {
  text-decoration: underline;
}

.mobileNav_mobileNav__NWDrv {
  display: none;
}

@media screen and (max-width: 1000px) {
  .mobileNav_mobileNav__NWDrv {
    display: block;
    position: absolute;
    left: 16px;
    top: 16px;
  }
}

.articleOverlay_articleOverlay__3SQty {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
}

.articleOverlay_articleOverlayContent__3rJWc {
  max-width: 704px;
  background: var(--background-color);
  border-radius: 8px;
  position: relative;
  min-height: 0;
  margin: 32px auto;
}

.articleOverlay_overlayCloseButton__2tz-i {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 8px;
}

@media screen and (max-width: 1000px) {
  .articleOverlay_articleOverlay__3SQty {
    background: var(--background-color);
    position: static;
  }

  .articleOverlay_articleOverlay__3SQty ~ * {
    display: none;
  }

  .articleOverlay_articleOverlayContent__3rJWc {
    width: auto;
    margin: 0;
    padding: 24px;
    position: static;
  }

  .articleOverlay_overlayCloseButton__2tz-i {
    display: none;
  }
}

.articleBlock_articleBlock__1UmgU {

}

.articleBlock_articleBlock__1UmgU img {
  max-width: 100%;
  display: block;
}

.articleBlock_articleBlock__1UmgU .articleBlock_articleContent__3ZYOa {
  padding: 48px 96px;
}

.articleBlock_articleBlock__1UmgU .articleBlock_articleMedia__2GgJR + .articleBlock_articleContent__3ZYOa {
  padding-top: 32px;
}

.articleBlock_articleBlockList__1QNki {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  margin-bottom: 16px;
  border-radius: 8px;
}

.articleBlock_articleBlockList__1QNki .articleBlock_articleMedia__2GgJR {
  -webkit-flex: 0 0 200px;
          flex: 0 0 200px;
  margin-right: 16px;
}

.articleBlock_articleBlockList__1QNki .articleBlock_articleContent__3ZYOa,
.articleBlock_articleBlockList__1QNki .articleBlock_articleMedia__2GgJR + .articleBlock_articleContent__3ZYOa {
  padding: 0;
}

.articleBlock_articleExcerpt__3zSC2 {
  margin-bottom: 16px;
  font-size: 1.1em;
}

.articleBlock_articleBlock__1UmgU h1 {
  margin: 0 0 8px;
  font-size: 1.4em;
  font-weight: normal;
}

.articleBlock_articleBlock__1UmgU h2 {
  font-size: 1.2em;
  font-weight: normal;
  margin: 1.2em 0 0.2em;
}

.articleBlock_articleBlock__1UmgU h3 {
  font-size: 1em;
  font-weight: normal;
}

.articleBlock_articleBlock__1UmgU .articleBlock_articleMeta__1Q-ai {
  font-size: 12px;
  opacity: 0.6;
  font-weight: normal;
  display: block;
}

@media screen and (max-width: 1000px) {
  .articleBlock_articleBlock__1UmgU .articleBlock_articleMedia__2GgJR + .articleBlock_articleContent__3ZYOa {
    padding-top: 16px;
  }

  .articleBlock_articleBlockList__1QNki .articleBlock_articleMedia__2GgJR {
    margin: 0;
  }

  .articleBlock_articleBlockList__1QNki .articleBlock_acticleContent__1zBSM {
    padding: 8px;
  }

  .articleBlock_articleBlock__1UmgU .articleBlock_articleContent__3ZYOa {
    padding: 0;
  }
}

.shareList_shareList__2P6Yo {
  list-style-type: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0;
  margin: 0;
}

.shareList_shareList__2P6Yo li {
  margin: 0 16px;
  cursor: pointer;
}

.shareList_shareList__2P6Yo svg {
  height: 48px;
}

.relatedArticlesList_relatedArticlesList__3XKw6 {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.relatedArticlesList_relatedArticlesList__3XKw6 li {

}

.relatedArticlesList_relatedArticlesList__3XKw6 li + li {
    border-top: thin solid var(--separator-color);
}

.filterSwitch_filterSwitch__2q3OW {
  font-size: 10px;
  text-transform: uppercase;
}

.filterSwitch_filterSwitch__2q3OW input {
  display: none;
}

.filterSwitch_filterSwitch__2q3OW span {
  display: block;
  border-radius: 2px;
  border: thin solid var(--separator-color);
  padding: 4px;
  cursor: pointer;
}

.filterSwitch_filterSwitch__2q3OW input:checked + span {
  background: var(--separator-color);
  color: var(--background-color);
}

.appSidebar_appSidebar__2qAYq { 
  -webkit-flex: 0 0 300px; 
          flex: 0 0 300px;
  margin-right: 32px;
}

.appSidebar_appSidebar__2qAYq fieldset {
  margin: 1em 0;
}

.appSidebar_appSidebar__2qAYq legend {
  font-weight: lighter;
  font-size: 1.5em;
}

@media screen and (max-width: 1000px) {
  .appSidebar_appSidebar__2qAYq {
    box-sizing: border-box;
    max-width: 100vw;
    width: 460px;
    margin-right: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: var(--background-color);
    padding: 24px;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 200ms ease-in-out;
    transition: -webkit-transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out;
    transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    border-right: thin solid var(--separator-color);
    z-index: 1;
  }

  .appSidebar_appSidebarOpen__1muFJ {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  .appSidebar_appSidebarOpen__1muFJ ~ * {
    height: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
  }
}

.filterList_filterList__1Chow {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px 18px;
  margin: 0;
  padding: 0;
}

.filterList_filterList__1Chow li {

}

.appSearchField_appSearchField__gt_DS {
  margin-bottom: 24px;
  display: block;
}

.appSearchField_appSearchField__gt_DS input {
  width: 100%;
  border: thin solid var(--separator-color);
  font: inherit;
  padding: 8px 16px;
}

.articleListLink_articleListLink__22-IZ {
  text-decoration: none;
  color: inherit;
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 1000px) {
  .articleListLink_articleListLink__22-IZ {
    display: block;
  }
}

@-webkit-keyframes spinner_spin__34-NC {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes spinner_spin__34-NC {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.spinner_spinner__1RoZu {
  -webkit-animation: spinner_spin__34-NC infinite 1s linear;
          animation: spinner_spin__34-NC infinite 1s linear;
}
.loadingIndicator_loadingIndicator__QrcDM {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 32px;
}

.appContent_appContent__2Y3LS {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.appContent_appContentGames__7MLq_ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 32px;
}

@media screen and (max-width: 1000px) {
  .appContent_appContent__2Y3LS {
    padding: 24px;
  }
  
  .appContent_appContentGames__7MLq_ {
    display: block;
  }
}

.appPage_appPage__3eIGX {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: var(--breakpoint-max);
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
  .appPage_appPage__3eIGX {
    width: 100%;
    display: block;
    position: relative;
    -webkit-flex: 1 1;
            flex: 1 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
  }
}

.gameBlock_gameBlock__qhPSg {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  padding: 16px;
}

.gameBlock_gameBlock__qhPSg img {
  width: 100%;
  display: block;
}

