.appLogo {
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: lighter;
}

@media screen and (max-width: 1000px) {
  .appLogo {
    padding: 16px 0 0;
  }
}
