body {
  margin: 0;
  font-size: var(--font-size-main);
  font-family: var(--font-family-main);
  color: var(--color-main);
  background: var(--background-color-main)
}

@media screen and (max-width: 1000px) {
  #root {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
  }
}
