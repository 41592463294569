.appContent {
  flex: 1;
}

.appContentGames {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 32px;
}

@media screen and (max-width: 1000px) {
  .appContent {
    padding: 24px;
  }
  
  .appContentGames {
    display: block;
  }
}
