.filterSwitch {
  font-size: 10px;
  text-transform: uppercase;
}

.filterSwitch input {
  display: none;
}

.filterSwitch span {
  display: block;
  border-radius: 2px;
  border: thin solid var(--separator-color);
  padding: 4px;
  cursor: pointer;
}

.filterSwitch input:checked + span {
  background: var(--separator-color);
  color: var(--background-color);
}
