:root {
  /* Colors */
  --separator-color: black;
  --background-color: white;
  --color-link-main: blue;
  --color-main: black;

  /* Fonts */
  --font-size-main: 1rem;
  --font-family-main: Helvetica, Arial, sans-serif;

  /* Breakpoints */
  --breakpoint-min: 1000px;
  --breakpoint-max: 1200px;
}
