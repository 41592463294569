.articleOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
}

.articleOverlayContent {
  max-width: 704px;
  background: var(--background-color);
  border-radius: 8px;
  position: relative;
  min-height: 0;
  margin: 32px auto;
}

.overlayCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 8px;
}

@media screen and (max-width: 1000px) {
  .articleOverlay {
    background: var(--background-color);
    position: static;
  }

  .articleOverlay ~ * {
    display: none;
  }

  .articleOverlayContent {
    width: auto;
    margin: 0;
    padding: 24px;
    position: static;
  }

  .overlayCloseButton {
    display: none;
  }
}
